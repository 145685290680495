<template>
  <div class="fill-height" v-if="!isLoading">
    <v-row>
      <v-col cols="auto">
        <SideNav>
        </SideNav>
      </v-col>
      <v-col cols="12">
        <router-view />
      </v-col>
    </v-row>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
// import { mapState, mapActions } from 'vuex'

/* Components */
import Loader from '@/components/Base/Loader'
import SideNav from '@/components/Administration/SideNav'
import { mapMutations } from 'vuex'
import { colors } from '@/utils/colors'

export default {
  name: 'Contract',
  components: {
    Loader,
    SideNav
  },
  mixins: [],

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    // ...mapState('contract', ['contracts', 'topFolders']),
  },

  created () {
    const profile = localStorage.getItem('profile')
    if (profile !== 'Legal') {
      this.setSnackbar({
        text: 'No tiene permitido ingresar a este módulo',
        timeout: 3000,
        showing: true,
        color: colors.primary
      })
      this.$router.push('/portal')
    }
  },

  methods: {
    ...mapMutations({ setSnackbar: 'SET_SNACKBAR' })
  }
}
</script>

<style lang="scss">

</style>
