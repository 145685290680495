import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',{staticClass:"fill-height"},[_c(VNavigationDrawer,{attrs:{"permanent":"","absolute":"","expand-on-hover":""}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItem,{attrs:{"link":"","to":"/portal/configurations/users"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-multiple-outline")])],1),_c(VListItemTitle,[_vm._v("Usuarios")])],1),_c(VListItem,{attrs:{"link":"","to":"/portal/configurations/countries"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-flag-outline")])],1),_c(VListItemTitle,[_vm._v("Países")])],1),_c(VListItem,{attrs:{"link":"","to":"/portal/configurations/areas"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account-group-outline")])],1),_c(VListItemTitle,[_vm._v("Áreas / Perfiles")])],1),_c(VListItem,{attrs:{"link":"","to":"/portal/configurations/notifications"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-bell-outline")])],1),_c(VListItemTitle,[_vm._v("Notificaciones")])],1),_c(VListItem,{attrs:{"link":"","to":"/portal/configurations/justifications"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-clipboard-text-outline")])],1),_c(VListItemTitle,[_vm._v("Justificaciones")])],1),_c(VListItem,{attrs:{"link":"","to":"/portal/configurations/templatetypes"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-file-hidden")])],1),_c(VListItemTitle,[_vm._v("Tipos de plantilla")])],1)],1)],1)],1):_c('div',{staticClass:"loading-container"},[_c('Loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }