<template>
  <div class="fill-height" v-if="!isLoading">
    <v-navigation-drawer
      permanent
      absolute
      expand-on-hover
    >
      <v-list
        nav
        dense
      >
        <v-list-item link to="/portal/configurations/users">
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Usuarios</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/portal/configurations/countries">
          <v-list-item-icon>
            <v-icon>mdi-flag-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Países</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/portal/configurations/areas">
          <v-list-item-icon>
            <v-icon>mdi-account-group-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Áreas / Perfiles</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/portal/configurations/notifications">
          <v-list-item-icon>
            <v-icon>mdi-bell-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Notificaciones</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/portal/configurations/justifications">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Justificaciones</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/portal/configurations/templatetypes">
          <v-list-item-icon>
            <v-icon>mdi-file-hidden</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tipos de plantilla</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
  <div v-else class="loading-container">
    <Loader />
  </div>
</template>

<script>
// import { mapState, mapActions } from 'vuex'

/* Components */
import Loader from '@/components/Base/Loader'

export default {
  name: 'Contract',
  components: {
    Loader
  },

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    // ...mapState('contract', ['contracts', 'topFolders']),
  },

  created () {
  },

  methods: {
    // ...mapActions('contract', ['getDocuments'])
  }
}
</script>

<style lang="scss">

</style>
